<template>
  <div
    class="wet-section wet-section-hotel wet-full-height d-flex"
    :style="{
      backgroundImage: !onlyForm ? backgroundImage : '',
    }"
    :class="{
      'py-0': onlyForm,
    }"
  >
    <div
      :class="{
        'position-relative': true,
        'container-wide container': !onlyForm,
        'w-100': onlyForm,
      }"
    >
      <div class="h-100 d-flex flex-column justify-content-center">
        <div v-if="!onlyForm" class="wet-section-title ml-sm-4 mr-0">
          <h1 class="empty-ph text-white">{{ str.hotel.section_title }}</h1>
        </div>
        <div
          class="wet-section-box-rounded pb-1"
          :class="{
            'h-100': onlyForm,
          }"
        >
          <div class="font-weight-bold text-white">{{ str.hotel.offer_text }}</div>
          <div class="row align-items-end">
            <div class="col-12 col-xl mt-3 mb-0">
              <input-location
                ref="location"
                :label="str.hotel.location_label"
                :placeholder="str.hotel.location_placeholder"
                :hide-details=true
                :countries="countries"
                v-model="formData.location"
                id="rr"
                dark
                labeled
                :location-error-text="str.hotel.error_location"
                :fulladdress="true"
                @change-invalid-value="updateInvalidValue"
                @change-address="updateAddress"
              />
            </div>
          </div>
          <div class="row align-items-end">
            <div class="col-12 col-xl mt-3">
              <period-alt
                :startDate.sync="formData.startDate"
                :endDate.sync="formData.endDate"
                :startText="str.hotel.start_date_label"
                :endText="str.hotel.end_date_label"
                :validationRules="validationRules"
                :calendLang="lang"
                :defaultStartDate="defaultStartDate"
                :defaultEndDate="defaultEndDate"
                :dates.sync="formData.dates"
                dark
                labeled
              />
            </div>
          </div>
          <div class="row align-items-end mb-4">
            <div class="col-12 col-xl mt-4">
              <button class="wet-button wet-button-xs-full"
                      style="width: 100%;" @click="onNextClick">
                {{ str.hotel.btn_label }}
              </button>
            </div>
          </div>
          <div class="row justify-content-center text-white">
            <div
              :style="{
                maxWidth: '500px',
              }"
              class="col"
            >
              <div class="empty-ph text-center">
                {{ str.hotel.time_range_label }}
              </div>
              <product-form-time-slider
                ref="timeSlider"
                v-model="timeRange"
                :minTimeRange="minTimeRange"
                :maxTimeRange="maxTimeRange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import common from '@/mixins/common';
import periodAlt from '@/components/sections/periodAlt.vue';
import inputLocation from '@/components/ui/inputLocationUi.vue';
import places from '@/mixins/places';
import changeLang from '@/mixins/changeLang';
import productFormTimeSlider from '@/components/productFormTimeSlider.vue';
import partnerCodeMixin from '@/mixins/partner-code';
import LogService from '@/services/LogService';
import wpSection from '@/enums/wpSection';
import hotelStyleLandingPage from '@/mixins/hotelStyleLandingPage';
import { hotel, buy, commonApp } from '@/enums/stringIndices';

export default {
  mixins: [common, places, partnerCodeMixin, hotelStyleLandingPage, changeLang],
  props: {
    beforeOrder: {
      type: Function,
      default: () => Promise.resolve(),
    },
  },
  components: {
    inputLocation,
    periodAlt,
    productFormTimeSlider,
  },
  data() {
    return {
      businessType: 'hotel',
      userStatus: '5',
      startDate: new Date(),
      endDate: new Date(),
      defaultStartDate: null,
      defaultEndDate: null,
      current: '',
      crops: [],
      formData: {},
      invalidLocation: false,
      timeRange: [],
      screenWidth: 0,
      str: {
        hotel,
        buy,
        commonApp,
      },
    };
  },
  computed: {
    wpSizes() {
      return this.str.hotel.background_image.sizes;
    },
    validationRules() {
      return this.settings.calendar?.hotel || {};
    },
    countries() {
      return ['DE'];
    },
    minTimeRange() {
      if ('time_settings' in this.validationRules) {
        return Number(this.validationRules.time_settings[0].min_hours) || 0;
      }
      return 0;
    },
    maxTimeRange() {
      if ('time_settings' in this.validationRules) {
        return Number(this.validationRules.time_settings[0].max_hours) || 25;
      }
      return 25;
    },
    onlyForm() {
      return this.$route.query.iframe === '2';
    },
  },
  watch: {
    timeRange: {
      immediate: true,
      deep: true,
      /** time range to array */
      handler(value) {
        const hoursRange = value || [];
        const hours = [
          ...Array(hoursRange.length > 0 ? Math.max(...hoursRange) - Math.min(...hoursRange) : 0),
        ].map((el, i) => i + Math.min(...hoursRange));
        this.$set(this.formData, 'hours', hours);
      },
    },
    validationRules: {
      immediate: true,
      handler(validationRules) {
        LogService.log('time_settings found', 'time_settings' in validationRules);
        if ('time_settings' in validationRules) {
          const from = Number(validationRules.time_settings[0].default_start_hour);
          const to = Number(validationRules.time_settings[0].default_end_hour);
          this.timeRange = [from, to].filter((n) => !!n);
        }
      },
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      Promise.all([
        this.getWordPressStrings(
          [
            wpSection.HOTEL,
            wpSection.SECTIONS,
            wpSection.BUY,
            wpSection.COMMON_APP,
          ],
        ),
        this.$store.dispatch('settings/getProductSettings', 'hotel'),
      ]);
    },
    async onNextClick() {
      // await this.postProductAndFind(this.businessType);
      // this.defaultRedirect();
      const modal = {
        id: 'wet-buy-product-not-supported',
        content: `<div class="text-center">${this.str.buy.error_product_not_active}</div>`,
        actions: [
          {
            name: 'wet-buy-product-not-supported',
            title: this.str.commonApp?.ok,
            event: 'close',
          },
        ],
        close: this.onCloseModal,
      };
      this.$store.dispatch('addModal', modal);
    },
    updateInvalidValue(invalid) {
      LogService.log('Hotel update invalid location', invalid);
      this.invalidLocation = invalid;
    },
    updateAddress(address) {
      LogService.log('Hotel updateAddress', address);
      this.formData.location = address;
    },
  },
  mounted() {
    this.loadMapScript()
      .then(() => this.initMaps());
    this.$login();
    this.resize();
    this.$root.$on('resize', this.resize);
  },
  destroyed() {
    this.$root.$off('resize', this.resize);
  },
};
</script>

<style lang="scss" scoped>
  .wet-section-hotel {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    position: relative;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background:
        radial-gradient(circle, rgba(0,0,0,.3) 0%, rgba(0,0,0,.5) 70%, rgba(0,0,0,.8) 100%);
    }

    &>.container {
      position: relative;
    }
  }

  .wet-section-box-rounded {
    border-radius: var(--border-radius);
    background-color: #3B384D;
    padding: 1.5rem;
    margin: auto;
    max-width: 35rem;
  }
</style>
